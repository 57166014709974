<template>
  <b-card class="top-profiles">
    <b-row class="pr-3 mt-2">
      <b-col class="top-right-headings">
        <i class="ri-loader-2-line ri-xl align-middle pr-1" />
        Project status
      </b-col>
      <b-col class="text-right top-right-info">
        <b-badge
          :class="{
            'status-starting' : startingStatuses.includes(project.status),
            'status-active' : project.status === PROJECT_STATUSES.active.value,
            'status-closed' : project.status === PROJECT_STATUSES.closed.value || project.status === PROJECT_STATUSES.paused.value
          }"
        >
          {{ project.status }}
        </b-badge>
      </b-col>
    </b-row>
    <b-row class="pr-3 mt-3">
      <b-col class="top-right-headings">
        <i class="ri-calendar-line ri-xl align-middle pr-1" />
        Project created
      </b-col>
      <b-col class="text-right top-right-info">
        {{ formatDate(project.created_at) }}
      </b-col>
    </b-row>
    <b-row
      v-if="project.contacts && project.contacts.length"
      class="pr-3 mt-3"
    >
      <b-col class="top-right-headings">
        <i class="ri-user-line ri-xl align-middle pr-1" />
        Project owner
      </b-col>
      <b-col class="text-right top-right-info">
        {{ project.contacts.map(c => c.full_name).join(', ') }}
      </b-col>
    </b-row>
    <b-row class="pr-3 mt-3">
      <b-col class="top-right-headings">
        <i class="ri-emotion-happy-line ri-xl align-middle pr-1" />
        Relancer advisor
      </b-col>
      <b-col class="text-right top-right-info">
        {{ project.business.success_manager }}
      </b-col>
    </b-row>
    <!-- <b-row
      v-if="project.fee_ranges && project.fee_ranges.length && $route.name === 'ProjectSingle'"
      class="pr-3 mt-3 position-relative"
    >
      <b-col
        class="top-right-headings"
      >
        <i class="ri-coins-line ri-xl align-middle pr-1" />
        Project budget
      </b-col>
      <b-col class="text-right top-right-info mr-1">
        {{ project.fee_ranges.map(r => r.label).join(', ') }}
      </b-col>
      <span
        v-b-tooltip.hover
        class="lock-bg position-absolute"
        title="Not visible to recruiter"
      >
        <i class="ri-lock-line" />
      </span>
    </b-row> -->
  </b-card>
</template>

<script>
import { formatDate } from '@/helpers/date'
import { PROJECT_STATUSES } from '@/helpers/statuses'

export default {
  name: 'ProjectSummary',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      PROJECT_STATUSES,
      startingStatuses: [
        PROJECT_STATUSES.draft.value,
        PROJECT_STATUSES.open.value,
      ],
    }
  },
  methods: {
    formatDate,
  },
}
</script>

<style lang="scss" scoped>
.lock-bg {
  background: #605D9F;
  border-radius: 100%;
  right: 0px;
  top: 2px;
  height: 17px;
  width: 17px;
  i {
    position: absolute;
    top: -2px;
    right: 2px;
    font-size: 12px;
    color: white;
  }
}
.top-profiles {
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(216, 216, 216, 0.5);
  border-radius: 12px;
  border: none;
  min-height: 240px;
  .top-right-headings {
    color: #39364f;
    font-weight: 600;
    font-size: 12px;
    padding-right: 0px;
  }
  .top-right-info {
    font-weight: 400;
    font-size: 12px;
    color: #39364f;
    padding-left: 0px;
    padding-right: 4px;
  }
  .description {
    font-style: italic;
    font-weight: 300;
    line-height: 24px;
  }
}
</style>
