<template>
  <b-card
    no-body
    class="bottom-profiles p-3"
  >
    <b-row>
      <b-col class="h7 mt-2 mb-3">
        Recruiter Requirements
      </b-col>
    </b-row>
    <!-- Showcase -->
    <b-row v-if="project.most_important">
      <b-col class="mt-4">
        <label class="data-field-heading"> Your previous experience: </label>
      </b-col>
    </b-row>
    <b-row v-if="project.most_important">
      <b-col>
        <ul class="li-text">
          <li
            v-for="item in (project.most_important || '')
              .split('\n')
              .map(r => r.replace('- ', ''))
              .filter(Boolean)"
            :key="item"
            class="pb-2"
          >
            {{ item }}
          </li>
        </ul>
      </b-col>
    </b-row>
    <b-row>
      <!-- Scope -->
      <b-col
        v-if="project.scopes && project.scopes.length"
        class="mt-4"
        cols="4"
      >
        <b-row>
          <b-col>
            <i class="ri-settings-4-line ri-xl pr-1" />
            <label class="data-field-heading"> Scope of work </label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-badge
              v-for="scope in project.scopes"
              :key="scope.id"
              class="new-badge-dsg"
            >
              {{ scope.label }}
            </b-badge>
          </b-col>
        </b-row>
      </b-col>
      <!-- Recruiter types -->
      <b-col
        v-if="project.recruiter_types && project.recruiter_types.length"
        class="mt-4"
        cols="4"
      >
        <b-row>
          <b-col>
            <i class="ri-user-settings-line ri-xl pr-1" />
            <label class="data-field-heading"> Recruiter type </label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-badge
              v-for="type in project.recruiter_types"
              :key="type.id"
              class="new-badge-dsg"
            >
              {{ type.label }}
            </b-badge>
          </b-col>
        </b-row>
      </b-col>
      <!-- Location -->
      <b-col
        v-if="project.recruiter_location"
        class="mt-4"
        cols="4"
      >
        <b-row>
          <b-col>
            <i class="ri-map-pin-line ri-xl pr-1" />
            <label class="data-field-heading"> Location </label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-badge class="new-badge-dsg">
              {{ project.recruiter_location }}
            </b-badge>
          </b-col>
        </b-row>
      </b-col>
      <!-- Services -->
      <b-col
        v-if="project.services && project.services.length"
        class="mt-4"
        cols="4"
      >
        <b-row>
          <b-col>
            <i class="ri-user-shared-line ri-xl pr-1" />
            <label class="data-field-heading"> Services </label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-badge
              v-for="service in project.services"
              :key="service.id"
              class="new-badge-dsg"
            >
              {{ service.label }}
            </b-badge>
          </b-col>
        </b-row>
      </b-col>
      <!-- Pricing models -->
      <b-col
        v-if="project.pricing_models && project.pricing_models.length"
        class="mt-4"
        cols="4"
      >
        <b-row>
          <b-col>
            <i class="ri-money-euro-box-line ri-xl pr-1" />
            <label class="data-field-heading"> Pricing model </label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-badge
              v-for="pricingModel in project.pricing_models"
              :key="pricingModel.id"
              class="new-badge-dsg"
            >
              {{ pricingModel.label }}
            </b-badge>
          </b-col>
        </b-row>
      </b-col>
      <!-- Languages -->
      <b-col
        v-if="project.languages && project.languages.length"
        class="mt-4"
        cols="4"
      >
        <b-row>
          <b-col>
            <i class="ri-money-euro-box-line ri-xl pr-1" />
            <label class="data-field-heading"> Language </label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-badge
              v-for="language in project.languages"
              :key="language.id"
              class="new-badge-dsg"
            >
              {{ language.label }}
            </b-badge>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: 'RecruiterRequirements',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>
